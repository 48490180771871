<template>
  <div>
    <v-row>
      <v-col cols="6" md="3">
        <v-row>
          <v-col cols="12">
            <v-text-field @input="setSearch" label="Поиск" class="mb-3" outlined hide-details @keyup.enter="searchByValue">
              <template v-slot:append-outer>
                <v-icon @click="searchByValue">search</v-icon>
              </template>
              </v-text-field>
          </v-col>
        </v-row>
        

        <v-btn class="mb-3" :to="{ name: 'merchantCreate' }">Создать</v-btn>

        <v-card v-if="merchants.length>0">
          <v-list dense>
            <v-list-item-group color="primary">
              <v-list-item v-for="(item, i) in merchants" :key="i" @change="selectMerchant(item)">
                <v-list-item-content>
                  <v-row align="center" justify="center">
                    <v-col class="overflow-x-auto"><v-list-item-title v-text="item.name"></v-list-item-title></v-col>
                    <v-col align="right" md="auto"><v-icon v-if="item.isDisabled" color="red">lock</v-icon></v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="6" md="9" v-if="selectedMerchant">
        <merchant-detail />
        <div class="mb-5"></div>
        <v-btn @click="dialog = true">Добавить</v-btn>
        <div class="mb-5"></div>
        <machines />
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" v-if="dialog" width="500" scrollable>
      <machine-form @canceled="dialog = false" @saved="fetchMachines()" />
    </v-dialog>
  </div>
</template>

<script>
import MerchantDetail from "./MerchantDetail.vue";
import Machines from "../Machines/Machines.vue";
import MachineForm from "../Machines/MachineForm.vue";
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  components: { MerchantDetail, Machines, MachineForm },

  data() {
    return {
      dialog: false,
    }
  },

  computed: {
    ...mapState({
      search: (state) => state.merchants.search,
      selectedMerchant: (state) => state.merchants.selected,
      merchants: (state) => state.merchants.merchants,
    }),
  },

  methods: {
    searchByValue() {
      this.fetchMerchants();
    },
    ...mapMutations({
      selectMerchant: "merchants/SELECT_MERCHANT",
      setSearch: "merchants/SET_SEARCH",
    }),
    ...mapActions("merchants", ["fetchMerchants"]),
    ...mapActions("machines", ["fetchMachines"]),
  },

  created() {
    this.fetchMerchants();
  },
};
</script>